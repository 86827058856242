import { InjectionToken } from '@angular/core';
import { RgLimitsParallelTimeValuesApiKind } from './types';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const RG_LIMITS_PARALLEL_API_SINGLE_LIMIT_UPDATE_MODE: InjectionToken<boolean> = new InjectionToken(
  'rgLimitsParallelApiSingleLimitUpdateMode',
  {
    factory: () => false
  }
);

// eslint-disable-next-line @typescript-eslint/naming-convention
export const RG_LIMITS_PARALLEL_TIME_VALUES_API_KIND: InjectionToken<RgLimitsParallelTimeValuesApiKind> =
  new InjectionToken<RgLimitsParallelTimeValuesApiKind>('rgLimitsParallelTimeValuesApiKind', {
    factory: () => 'hours'
  });

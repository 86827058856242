import { Provider } from '@angular/core';
import { abiosConfigName } from '@bcf-v2-configurators/tokens/abios/letsbetmd/abios-config-name';
import { ABIOS_CONFIG_NAME } from '@bcf-v2-configurators/tokens/abios/token';
import { possibleWinningAboveWinTresholdMode } from '@bcf-v2-configurators/tokens/betslip/letsbetmd/possible-winning-above-win-treshold-mode';
import { possibleWinningMax } from '@bcf-v2-configurators/tokens/betslip/letsbetmd/possible-winning-max';
import {
  POSSIBLE_WINNING_ABOVE_WIN_TRESHOLD_MODE,
  POSSIBLE_WINNING_MAX
} from '@bcf-v2-configurators/tokens/betslip/token';
import { betslipDateFormat } from '@bcf-v2-configurators/tokens/date-format/letsbetmd/betslip-date-format';
import { matchOfferDateTimeFormat } from '@bcf-v2-configurators/tokens/date-format/letsbetmd/match-offer-date-time-format';
import { myBetsDateFormat } from '@bcf-v2-configurators/tokens/date-format/letsbetmd/my-bets-date-format';
import { myBetsMatchDateFormat } from '@bcf-v2-configurators/tokens/date-format/letsbetmd/my-bets-match-date-format';
import { userPanelDateFormat } from '@bcf-v2-configurators/tokens/date-format/letsbetmd/user-panel-date-format';
import { userPanelDateTimeFormat } from '@bcf-v2-configurators/tokens/date-format/letsbetmd/user-panel-date-time-format';
import {
  BETSLIP_DATE_FORMAT,
  MATCH_OFFER_DATE_TIME_FORMAT,
  MY_BETS_DATE_FORMAT,
  MY_BETS_MATCH_DATE_FORMAT,
  USER_PANEL_DATE_FORMAT,
  USER_PANEL_DATE_TIME_FORMAT
} from '@bcf-v2-configurators/tokens/date-format/token';
import { I18N_OPTIMOVE_DESC_LOADER } from '@bcf-v2-configurators/tokens/descs-optimove-i18n/token';
import { I18N_DESC_LOADER } from '@bcf-v2-configurators/tokens/descs/token';
import {
  editProfileAddressAvailableFieldsFn,
  editProfilePrivacyAndNotificationsAvailableFields
} from '@bcf-v2-configurators/tokens/edit-profile/lvbetcom/edit-profile-available-fields';
import {
  EDIT_PROFILE_ADDRESS_AVAILABLE_FIELDS,
  EDIT_PROFILE_PRIVACY_AND_NOTIFICATIONS_AVAILABLE_FIELDS
} from '@bcf-v2-configurators/tokens/edit-profile/token';
import { footerBrandName } from '@bcf-v2-configurators/tokens/footer/letsbetmd/footer-brand-name';
import { footerLinksFn } from '@bcf-v2-configurators/tokens/footer/letsbetmd/footer-links';
import { FOOTER_BRAND_NAME, FOOTER_LINKS } from '@bcf-v2-configurators/tokens/footer/token';
import { googleMapsApiToken } from '@bcf-v2-configurators/tokens/google-autocomplete/letsbetmd/google-maps-api-token';
import { GOOGLE_MAPS_API_KEY } from '@bcf-v2-configurators/tokens/google-autocomplete/token';
import { ladeskChatConfig } from '@bcf-v2-configurators/tokens/ladesk-chat/letsbetmd/ladesk-chat-config';
import { LADESK_CHAT_CONFIG } from '@bcf-v2-configurators/tokens/ladesk-chat/token';
import { LANGUAGE_SWITCHER_FLAG_MAP } from '@bcf-v2-configurators/tokens/language-switcher/token';
import { usaLanguageSwitcherFlagMap } from '@bcf-v2-configurators/tokens/language-switcher/usa/usa-language-switcher-flag-map';
import { lvisionBetBoosterApiKeys } from '@bcf-v2-configurators/tokens/lvision-bet-booster/letsbetmd/lvision-bet-booster-api-keys';
import { LVISION_BET_BOOSTER_API_KEYS } from '@bcf-v2-configurators/tokens/lvision-bet-booster/token';
import { oddsFormatDefault } from '@bcf-v2-configurators/tokens/match-offer/letsbetmd/odds-format-default';
import { primaryColumnSettingsMode } from '@bcf-v2-configurators/tokens/match-offer/letsbetmd/primary-column-settings-mode';
import {
  LIVE_MATCH_IN_CARD_STYLE_ENABLED,
  ODDS_FORMAT_DEFAULT,
  PRIMARY_COLUMN_SETTINGS_MODE,
  USA_KIND_STYLE_ODDS_ENABLED
} from '@bcf-v2-configurators/tokens/match-offer/tokens';
import { mobileApplicationAppConfig } from '@bcf-v2-configurators/tokens/mobile-application/sportsbook/letsbetmd/config';
import { MOBILE_APPLICATION_APPS_CONFIG } from '@bcf-v2-configurators/tokens/mobile-application/token';
import { commonTaxPayoutDetailsInfo } from '@bcf-v2-configurators/tokens/my-bets/letsbetmd/common-tax-payout-details-info';
import { customTaxPayoutDetailsInfo } from '@bcf-v2-configurators/tokens/my-bets/letsbetmd/custom-tax-payout-details-info';
import {
  COMMISSION_ENABLED,
  COMMON_TAX_PAYOUT_DETAIL_INFO,
  CUSTOM_TAX_PAYOUT_DETAIL_INFO
} from '@bcf-v2-configurators/tokens/my-bets/token';
import { OPTIMOVE_SDK_CONFIG_LOADER } from '@bcf-v2-configurators/tokens/optimove-sdk/tokens';
import { PUSH_NOTIFICATIONS_CONFIG_LOADER } from '@bcf-v2-configurators/tokens/push-notifications/token';
import { rgLimitsParallelApiSingleLimitUpdateMode } from '@bcf-v2-configurators/tokens/rg-limits-parallel/letsbetmd/rg-limits-parallel-api-single-limit-update-mode';
import { rgLimitsParallelTimeValuesApiKind } from '@bcf-v2-configurators/tokens/rg-limits-parallel/letsbetmd/rg-limits-parallel-time-values-api-kind';
import {
  RG_LIMITS_PARALLEL_API_SINGLE_LIMIT_UPDATE_MODE,
  RG_LIMITS_PARALLEL_TIME_VALUES_API_KIND
} from '@bcf-v2-configurators/tokens/rg-limits-parallel/token';
import { statscoreVizuConfig } from '@bcf-v2-configurators/tokens/statscore-vizu-config/letsbetmd/statscore-vizu-config';
import { STATSCORE_VIZU_CONFIG } from '@bcf-v2-configurators/tokens/statscore-vizu-config/token';
import { enabledBonusCancel } from '@bcf-v2-configurators/tokens/user-bonuses/letsbetmd/enabled-bonus-cancel';
import { ENABLED_BONUS_CANCEL } from '@bcf-v2-configurators/tokens/user-bonuses/token';

export const settingsProviders: Provider[] = [
  {
    provide: I18N_DESC_LOADER,
    useValue: () =>
      import(
        /* webpackChunkName: "const-i18n-desc" */ '@bcf-v2-configurators/tokens/descs/letsbetmd/mobile-web/i18n-desc'
        // eslint-disable-next-line @typescript-eslint/typedef
      ).then((mod) => mod.i18nDesc)
  },
  {
    provide: USA_KIND_STYLE_ODDS_ENABLED,
    useValue: true
  },
  {
    provide: LIVE_MATCH_IN_CARD_STYLE_ENABLED,
    useValue: false
  },
  {
    provide: ODDS_FORMAT_DEFAULT,
    useValue: oddsFormatDefault
  },
  {
    provide: PRIMARY_COLUMN_SETTINGS_MODE,
    useValue: primaryColumnSettingsMode
  },
  {
    provide: MOBILE_APPLICATION_APPS_CONFIG,
    useValue: mobileApplicationAppConfig
  },
  {
    provide: LANGUAGE_SWITCHER_FLAG_MAP,
    useValue: usaLanguageSwitcherFlagMap
  },
  {
    provide: EDIT_PROFILE_PRIVACY_AND_NOTIFICATIONS_AVAILABLE_FIELDS,
    useValue: editProfilePrivacyAndNotificationsAvailableFields
  },
  {
    provide: EDIT_PROFILE_ADDRESS_AVAILABLE_FIELDS,
    useValue: editProfileAddressAvailableFieldsFn
  },
  {
    provide: LADESK_CHAT_CONFIG,
    useValue: ladeskChatConfig
  },
  {
    provide: STATSCORE_VIZU_CONFIG,
    useValue: statscoreVizuConfig
  },
  {
    provide: ABIOS_CONFIG_NAME,
    useValue: abiosConfigName
  },
  {
    provide: ENABLED_BONUS_CANCEL,
    useValue: enabledBonusCancel
  },
  {
    provide: USER_PANEL_DATE_FORMAT,
    useValue: userPanelDateFormat
  },
  {
    provide: USER_PANEL_DATE_TIME_FORMAT,
    useValue: userPanelDateTimeFormat
  },
  {
    provide: GOOGLE_MAPS_API_KEY,
    useValue: googleMapsApiToken
  },
  {
    provide: POSSIBLE_WINNING_ABOVE_WIN_TRESHOLD_MODE,
    useValue: possibleWinningAboveWinTresholdMode
  },
  {
    provide: POSSIBLE_WINNING_MAX,
    useValue: possibleWinningMax
  },
  {
    provide: MATCH_OFFER_DATE_TIME_FORMAT,
    useValue: matchOfferDateTimeFormat
  },
  {
    provide: BETSLIP_DATE_FORMAT,
    useValue: betslipDateFormat
  },
  {
    provide: MY_BETS_DATE_FORMAT,
    useValue: myBetsDateFormat
  },
  {
    provide: MY_BETS_MATCH_DATE_FORMAT,
    useValue: myBetsMatchDateFormat
  },
  {
    provide: FOOTER_LINKS,
    useValue: footerLinksFn
  },
  {
    provide: FOOTER_BRAND_NAME,
    useValue: footerBrandName
  },
  {
    provide: PUSH_NOTIFICATIONS_CONFIG_LOADER,
    useValue: () =>
      import('@bcf-v2-configurators/tokens/push-notifications/letsbetmd/push-notifications-config').then(
        // eslint-disable-next-line @typescript-eslint/typedef
        (mod) => mod.pushNotificationsConfigs
      )
  },
  {
    provide: RG_LIMITS_PARALLEL_TIME_VALUES_API_KIND,
    useValue: rgLimitsParallelTimeValuesApiKind
  },
  {
    provide: RG_LIMITS_PARALLEL_API_SINGLE_LIMIT_UPDATE_MODE,
    useValue: rgLimitsParallelApiSingleLimitUpdateMode
  },
  {
    provide: OPTIMOVE_SDK_CONFIG_LOADER,
    useValue: () =>
      import('@bcf-v2-configurators/tokens/optimove-sdk/letsbetmd/optimove-sdk-config').then(
        // eslint-disable-next-line @typescript-eslint/typedef
        (mod) => mod.optimoveSdkConfigs
      )
  },
  {
    provide: I18N_OPTIMOVE_DESC_LOADER,
    useValue: () =>
      import('@bcf-v2-configurators/tokens/descs-optimove-i18n/letsbetmd/sportsbook/i18n-optimove-desc').then(
        // eslint-disable-next-line @typescript-eslint/typedef
        (mod) => mod.i18nOptimoveDesc
      )
  },
  {
    provide: LVISION_BET_BOOSTER_API_KEYS,
    useValue: lvisionBetBoosterApiKeys
  },
  {
    provide: COMMISSION_ENABLED,
    useValue: false
  },
  {
    provide: COMMON_TAX_PAYOUT_DETAIL_INFO,
    useValue: commonTaxPayoutDetailsInfo
  },
  {
    provide: CUSTOM_TAX_PAYOUT_DETAIL_INFO,
    useValue: customTaxPayoutDetailsInfo
  }
];
